<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column"></h3>
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm"
          @click="createFileClicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('PAGES.FILES.NEW_FILE') }}</a
        >
      </div>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-header border-0 py-5 m-1">
      <div>
        <b-form-select v-model="perPage" :options="options"></b-form-select>
      </div>
      <b-pagination
        class="ml-auto mb-0 align-middle"
        v-model="currentPage"
        :total-rows="items.length"
        :per-page="perPage"
        aria-controls="advance-file-table"
        first-number
        last-number
      ></b-pagination>
    </div>
    <!--begin::Table-->

    <div class="card-body pt-0 table-responsive">
      <b-table
        id="advance-file-table"
        :fields="headers"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        sort-by="created_at"
        :sort-desc="true"
        head-variant="light"
        sticky-header
        class="mh-100 table-striped"
      >
        <template #head(name)="data">
          <tip-label :tip="$t('PAGES.FILES.FILE_NAME_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #head(descr)="data">
          <tip-label :tip="$t('PAGES.FILES.DISPLAY_NAME_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #head(created_at)="data">
          <tip-label :tip="$t('PAGES.FILES.DATE_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #head(file_id)="data">
          <tip-label :tip="$t('PAGES.FILES.HANDLE_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #cell(file_id)="row">
          <div class="justify-content-end d-flex">
            <a class="btn btn-icon btn-light btn-sm mx-3" @click="copyLinkClicked(row.item)" v-b-tooltip="{ title: $t('PAGES.FILES.COPY_LINK_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
              <span class="svg-icon svg-icon-md text-primary">
                <i class="fa fa-link color-primary"></i>
              </span>
            </a>
            <a href="#" class="btn btn-icon btn-light btn-sm mr-3" @click.prevent="downloadFileClicked(row.item.file_id)" v-b-tooltip="{ title: $t('PAGES.FILES.DOWNLOAD_FILE_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
              <span class="svg-icon svg-icon-md svg-icon-primary" >
                <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
              </span>
            </a>
            <a href="#" class="btn btn-icon btn-light btn-sm mr-3" @click.prevent="selectFileClicked(row.item.file_id)" v-b-tooltip="{ title: $t('PAGES.FILES.EDIT_FILE_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
              <span class="svg-icon svg-icon-md svg-icon-primary" >
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="deleteFileClicked(row.item.file_id)" v-b-tooltip="{ title: $t('PAGES.FILES.DELETE_FILE_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>
      <!--end::Table-->
    </div>

    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<style lang="scss" scoped>



</style>
<script>

import TipLabel from '@/view/components/TipLabel.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import dayjs from 'dayjs';

export default {
  name: 'files-table',
  props: ['items', 'currentCompanyId'],
  emits: ['createFileClicked', 'downloadFileClicked', 'selectFileClicked', 'deleteFileClicked'],
  mixins: [ toasts ],
  components: { TipLabel },
  methods: {
    createFileClicked() {
      this.$emit('createFileClicked');
    },
    downloadFileClicked(file_id) {
      this.$emit('downloadFileClicked', file_id);
    },
    selectFileClicked(file_id) {
      this.$emit('selectFileClicked', file_id);
    },
    deleteFileClicked(file_id) {
      this.$emit('deleteFileClicked', file_id);
    },
    copyLinkClicked(file) {
      navigator.clipboard
        .writeText(get_base_url() + `/dlfile/${file.linkstr}`)
        .then(() => {
          this.toastr('success', 'Kopierad', 'Länken kopierades till urklipp');
        })
        .catch(() => {
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte kopiera länken');
        });
    },

  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      options: [100, 150, 200],
      headers: [
        {
          key: 'name',
          label: this.$t('PAGES.FILES.FILE_NAME'),
          sortable: true,
          thClass: '',
          tdClass: 'td-contain'
        },
        {
          key: 'descr',
          label: this.$t('PAGES.FILES.DISPLAY_NAME'),
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7'
        },
        {
          key: 'created_at',
          label: this.$t('PAGES.FILES.DATE'),
          sortable: true,
          thClass: 'w-150px',
          formatter: (_, __, item) => {
            return dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss');
          }
        },
        
        {
          key: 'file_id',
          label: this.$t('PAGES.FILES.HANDLE'),
          thClass: 'w-150px text-right pr-22'
        }
      ],
      list: []
    };
  }
};
</script>
